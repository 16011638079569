<template>
     <CForm ref="form" class="needs-validation">
        <CRow>
            <CCol>
                <input name="file" id="file" type="file" @change="handleFileUpload($event)" accept="image/*"/>

            </CCol>
        </CRow>
    </CForm>
</template>

<script>
    export default{
        name: "KLAddPhoto",
        props:{
            validated: Boolean,
            params: Object,
            actionType: String,
            validated: Boolean,
        },
        watch:{
            params: function(val){
                this.data = {...val};
            },
            actionType:  function(val){
                if(val == 'create')
                    this.data = {...this.params}
            },

        },
        data: function(){
            return{
                data: this.params ? {...this.params} : {},
            }
        },
        methods:{
            handleFileUpload: function(event){
                let file = event.target.files[0]
                let status = true
                let msg = {}
                if(file.size > 1500000){
                    status = false
                    msg = {
                        msg: 'Görsel boyutu en fazla 1.5 mb olabilir',
                        header: 'Hata',
                        color: 'danger'
                    }
                }

                if(status){
                    this.data.file = file;
                }else{
                    event.target.value = ''
                    this.$store.commit('showToast', msg)
                }
            }
        }
    }
</script>